import * as React from 'react';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import axios from 'axios';

import FullPageLoader from "../components/FullPageLoader";
import Header from "../components/Header";
import useAuth from "../services/Auth";

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth(localStorage.getItem('token')?.split(';')?.[0]);

  const [loading, setLoading] = React.useState(false);

  const token = localStorage.getItem('token')?.split(';')?.[0];
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

  React.useEffect(() => {
    if (location.pathname !== '/login' && !localStorage.getItem('token')) {
      navigate('/login');
    }

    axios.interceptors.response.use(function (response) {
      return response;
    }, async function (error) {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== '/auth/refresh') {
        originalRequest._retry = true;
        const successRefresh = await auth.refresh();

        if (successRefresh) {
          originalRequest.headers.set('Authorization', 'Bearer ' + successRefresh.access_token);
          return axios(originalRequest);
        }

        auth.logout();
      } else if (error.response.status === 401 && originalRequest._retry && originalRequest.url !== '/auth/refresh') {
        auth.logout();
      } else if (error.response.status === 401 && originalRequest.url === '/auth/refresh') {
        auth.logout();
      }
      return Promise.reject(error);
    });

    if (token) {
      setLoading(true);

      auth.loadUser()
        .then(() => {
            setLoading(false);
        })
        .catch(({ cause }) => {
          if (cause === 'no-meos-access') auth.logout();
        });
    }
  }, []);

  return (
    <div
      style={{
        paddingBottom: 30,
      }}
    >
      {
        loading && (
          <FullPageLoader/>
        )
      }
      {
        !loading && (
          <>
            <Header/>
            <Outlet/>
          </>
        )
      }
    </div>
  );
};

export default Layout;
