import * as React from 'react';
import axios from 'axios';

import Input from "../components/Input";
import useDimensions from "../services/useDimensions";
import {useNavigate} from "react-router-dom";
import LicensePlate from "../components/LicensePlate";

const Vehicles = () => {
  const size = useDimensions();
  const navigate = useNavigate();

  const [search, setSearch] = React.useState('');
  const [fetching, setFetching] = React.useState(false);
  const [vehicles, setVehicles] = React.useState([]);

  const onChange = () => {
    if (search !== '') {
      axios.get('/api/meos/vehicles', {
        params: {
          search,
        }
      })
        .then(({data}) => {
          setVehicles(data);
        })
        .catch(() => alert('Something went wrong.'))
        .finally(() => setFetching(false));
    } else {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    const timeout = setTimeout(onChange, 750);
    if (search !== '') setFetching(true);

    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <div
      style={{
        paddingTop: 35,
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          width: size.width <= 1122 ? '100%' : '65%',
        }}
      >
        <div
          style={{
            width: '80%',
            backgroundColor: '#ffffff',
            margin: '0 auto',
            padding: '25px 25px',
            borderRadius: 3,
          }}
        >
          <h3>Voertuigen zoeken</h3>
          <Input
            value={search}
            onChange={e => setSearch(e.target.value.toUpperCase())}
            loading={fetching}
          />
        </div>

        {
          !!vehicles.length && (
            <div
              style={{
                width: '80%',
                backgroundColor: '#ffffff',
                margin: '0 auto',
                padding: '25px 25px',
                borderRadius: 3,
                marginTop: 15,
              }}
            >
              {
                vehicles.map((data, index) => (
                  <div
                    key={data.citizenid}
                    style={{
                      display: 'flex',
                      marginTop: index !== 0 && 10,
                      borderTop: index !== 0 && '1px solid grey',
                      paddingTop: 10,
                      cursor: data.owner ? 'pointer' : 'not-allowed',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => data.owner && navigate('/civilian/' + data.owner.citizenid)}
                  >
                    <div>
                      <span>Voertuig: {data.vehicle}</span>
                      <br />
                      {
                        !data.owner
                        ? <span>Eigenaar niet gevonden</span>
                          : <span>Eigenaar: {data.owner.full_name}</span>
                      }
                    </div>
                    <div
                      style={{
                        marginRight: 100,
                      }}
                    >
                      <LicensePlate>{data.plate}</LicensePlate>
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    </div>

  );
}

export default Vehicles;
