import * as React from 'react';
import Loader from "./Loader";

const Input = (props) => {
  return (
    <div
      style={{
        border: '1px solid #D6D6D6',
        borderRadius: 4,
        display: 'flex',
        backgroundColor: '#ffffff',
      }}
    >
      <input
        type={props.type ?? 'text'}
        placeholder={props.placeholder ?? 'Search ...'}
        style={{
          padding: '13px 7px',
          width: '95%',
          fontSize: 16,
          outline: 'none',
          fontFamily: 'Quicksand, sans-serif',
          border: 'none',
          borderRadius: 4,
        }}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
      />
      <div
        style={{
          width: '5%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {
          props.loading && (
            <Loader
              size={30}
            />
          )
        }
      </div>
    </div>
  )
};

export default Input;
