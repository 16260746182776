import * as React from 'react';

const FullPageLoader = () => {
  const [dots, setDots] = React.useState(1);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDots(prevState => (prevState + 1) % 4)
    }, 500);

    return () => clearTimeout(timeout);
  });

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: 100,
        }}
      >
        Loading
        {
          [...Array(dots)].map(() => '.')
        }
      </div>
    </div>
  );
};

export default FullPageLoader;
