import * as React from 'react';
import {useDispatch} from "react-redux";
import axios from "axios";

import {login, logout as userLogout} from "../redux/slices/userSlice";

const useAuth = () => {
  const dispatch = useDispatch();

  const loadUser = () => new Promise((resolve, reject) => {
    axios.get('/api/auth/user', {
      params: {
        hasAccess: 'meos',
      },
    })
      .then(({data}) => {
        if (data.access.meos) {
          const expiration = parseInt(localStorage.getItem('token')?.split(';')?.[1]);

          dispatch(login({
            ...data,
            expires_at: expiration,
          }));
          resolve(data);
          return;
        }

        reject(new Error('Failed.', { cause: 'no-meos-access' }));
      })
      .catch(() => {
        reject(new Error('Failed.', { cause: 'unknown' }));
      });
  });

  const refresh = () => new Promise((resolve, reject) => {
    axios.post('/auth/refresh')
      .then(({ data }) => {
        const expirationTimestamp = new Date().getTime() + data.expires_in * 1000;

        localStorage.setItem('token', data.access_token + ';' + expirationTimestamp);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;

        dispatch(login({
          expires_at: expirationTimestamp,
        }));

        resolve(data);
      })
      .catch(() => reject());
  });

  const logout = () => {
    localStorage.removeItem('token');
    axios.defaults.headers.common['Authorization'] = null;
    dispatch(userLogout());
  };

  return {
    loadUser,
    refresh,
    logout,
  };
};

export default useAuth;
