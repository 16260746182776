import * as React from 'react';
import Input from "./Input";
import axios from "axios";
import {useParams} from "react-router-dom";

const CriminalRecordItem = (props) => {
  const params = useParams();

  const [editing, setEditing] = React.useState(!props.id);

  const [reason, setReason] = React.useState(props.reason);
  const [fine, setFine] = React.useState(props.fine);
  const [sentence, setSentence] = React.useState(props.sentence);

  const create = () => {
    axios.post(`/api/meos/players/${params.id}/criminal-records`, {
        reason,
        fine,
        prison_sentence: sentence,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(({ data }) => {
        setReason('');
        setFine(null);
        setSentence(null);
        props.setCriminalRecord(prevState => [
          {
            id: data.id,
            reason,
            fine,
            prison_sentence: sentence,
            can_edit: true,
            created_at: new Date().toISOString(),
          },
          ...prevState,
        ])
      })
      .catch((err) => {
        console.log(err);
        window.alert('Something went wrong.');
      });
  };

  const edit = () => {
    axios.patch(`/api/meos/players/${params.id}/criminal-records/${props.id}`, {
      reason,
      fine,
      prison_sentence: sentence,
    },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(() => {
        setEditing(false);
        props.setCriminalRecord(prevState => prevState.map(data => {
          if (data.id !== props.id) return data;

          return {
            id: props.id,
            reason,
            fine,
            prison_sentence: sentence,
            can_edit: true,
            created_at: data.created_at,
          };
        }));
      })
      .catch((err) => {
        console.log(err);
        window.alert('Something went wrong.');
      });
  };

  const onSave = () => {
    if (!reason) return;

    if (props.id) {
      edit();
    } else {
      create();
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#004682',
        margin: 10,
        padding: '5px 10px',
        borderRadius: 7,
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          right: 5,
          top: 5,
          display: 'flex',
        }}
      >
        {
          editing && (
            <div
              style={{
                backgroundColor: reason ? '#ffffff' : '#bdbdbd',
                padding: 2,
                borderRadius: 7,
                cursor: 'pointer',
                marginRight: props.id && 7,
                userSelect: 'none',
              }}
              onClick={onSave}
            >
              Opslaan
            </div>
          )
        }
        {
          props.id && props.canEdit && (
            <div
              style={{
                backgroundColor: '#ffffff',
                padding: 2,
                borderRadius: 7,
                cursor: 'pointer',
                userSelect: 'none',
              }}
              onClick={() => setEditing(prevState => !prevState)}
            >
              Bewerken
            </div>
          )
        }
      </div>
      {
        !editing
          ? (
            <p
              style={{
                color: '#ffffff',
              }}
            >
              Reden: {reason}<br />
              Boete: {fine ? `€ ${fine}` : 'N.V.T'}<br />
              Celstraf: {sentence ? `${sentence} maanden` : 'N.V.T'} <br />
            </p>
          )
          : (
            <div
              style={{
                marginTop: 30,
              }}
            >
              <div
                style={{
                  marginBottom: 5,
                }}
              >
                <Input
                  placeholder="Reden"
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                />
              </div>
              <div
                style={{
                  marginBottom: 5,
                }}
              >
                <Input
                  type="number"
                  placeholder="Boetebedrag in euro"
                  value={fine}
                  onChange={e => setFine(e.target.value)}
                />
              </div>
              <Input
                type="number"
                placeholder="Celstraf in maanden"
                value={sentence}
                onChange={e => setSentence(e.target.value)}
              />
            </div>
          )
      }
    </div>
  );
};

export default CriminalRecordItem;
