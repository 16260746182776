import * as React from 'react';

import '../assets/css/Loader.css';

const Loader = ({ color, size, width }) => (
  <span
    className="loader"
    style={{
      border: `${width ?? 4}px solid ${color ?? '#000000'}`,
      width: size ?? 48,
      height: size ?? 48,
      borderBottomColor: 'transparent',
    }}
  />
);

export default Loader;
