import * as React from 'react';

/**
 * UseDimensions
 *
 * @return {type} - Returns the window dimensions of the screens
 */
export default function useDimensions() {
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    /**
     * handleResize
     *
     * @return {type} - Handles window resizes so the window dimensions stay up-to-date
     */
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
