import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    logged_in: false,
    id: null,
    email: null,
    username: null,
    avatar: null,
    created_at: null,
    expires_at: null,
  },
  reducers: {
    login: (state, action) => {
      state.logged_in = true;
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.username = action.payload.username;
      state.avatar = action.payload.avatar;
      state.created_at = action.payload.created_at;
      state.expires_at = action.payload.expires_at;
    },
    logout: (state) => {
      state.logged_in = false;
      state.id = null;
      state.email = null;
      state.username = null;
      state.avatar = null;
      state.created_at = null;
      state.expires_at = null;
    }
  },
})

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions

export default userSlice.reducer
