import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {login} from "../redux/slices/userSlice";
import {useNavigate} from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedIn = useSelector(state => state.user.logged_in);
  const [error, setError] = React.useState(null);

  const onClick = () => {
    setError(null);
    window.open(
      process.env.REACT_APP_API_URL + '/auth/discord/redirect',
      '_blank',
      'popup=true,height=700,width=450,left=25,top=25'
    );
  }

  const onMessage = ({data}) => {
    if (data.sender === 'hyper_api') {
      axios.get(process.env.REACT_APP_API_URL + '/api/auth/user', {
        params: {
          hasAccess: 'meos',
        },
        headers: {
          Authorization: 'Bearer ' + data.token,
        }
      })
        .then(({data: apiData}) => {
          if (apiData.access.meos) {
            const expirationTimestamp = new Date().getTime() + data.expires_in * 1000;

            dispatch(login({
              ...apiData,
              expires_at: expirationTimestamp,
            }));
            localStorage.setItem('token', data.token + ';' + expirationTimestamp);
            return;
          }

          setError('No access');
        })
        .catch(() => {
          setError('Something went wrong.');
        });
    }
  }

  React.useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []);

  React.useEffect(() => {
    if (loggedIn) navigate('/');
  }, [loggedIn]);

  return (
    <div
      style={{
        padding: 10,
      }}
    >
      <button
        onClick={onClick}
      >
        Login
      </button>
      {error}
    </div>
  );
};

export default Login;
