import * as React from 'react';

const SearchWarrantTag = ({ text }) => {
  return (
    <div
      style={{
        backgroundColor: '#CC0029',
        width: 'auto',
        color: '#ffffff',
        padding: '8px 15px',
        borderRadius: 6,
      }}
    >
      <span>{text ?? 'Arrestatiebevel'}</span>
    </div>
  );
};

export default SearchWarrantTag;
