import * as React from 'react';

const SquareButton = ({children, color, onClick}) => {

  return (
    <button
      style={{
        width: 48,
        height: 48,
        backgroundColor: color,
        border: 'none',
        borderRadius: 8,
        color: '#ffffff',
        fontSize: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default SquareButton;
