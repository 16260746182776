import * as React from 'react';
import Input from "../components/Input";
import useDimensions from "../services/useDimensions";
import UserProfile from "../components/UserProfile";
import SearchWarrantTag from "../components/SearchWarrantTag";
import {useNavigate} from "react-router-dom";
import axios from "axios";

function Civilians() {
  const size = useDimensions();
  const navigate = useNavigate();

  const [civilians, setCivilians] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [fetching, setFetching] = React.useState(false);

  const onChange = () => {
    if (search !== '') {
      axios.get('/api/meos/players', {
        params: {
          search,
        }
      })
        .then(({data}) => {
          setCivilians(data);
        })
        .catch(() => alert('Something went wrong.'))
        .finally(() => setFetching(false));
    } else {
      setFetching(false);
    }
  };

  React.useEffect(() => {
    const timeout = setTimeout(onChange, 750);
    if (search !== '') setFetching(true);

    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <div
      style={{
        paddingTop: 35,
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          width: size.width <= 1122 ? '100%' : '65%',
        }}
      >
        <div
          style={{
            width: '80%',
            backgroundColor: '#ffffff',
            margin: '0 auto',
            padding: '25px 25px',
            borderRadius: 3,
          }}
        >
          <h3>Burgers zoeken</h3>
          <Input
            onChange={e => setSearch(e.target.value)}
            loading={fetching}
          />
        </div>

        {
          !!civilians.length && (
            <div
              style={{
                width: '80%',
                backgroundColor: '#ffffff',
                margin: '0 auto',
                padding: '25px 25px',
                borderRadius: 3,
                marginTop: 15,
              }}
            >
              {
                civilians.map((data, index) => (
                  <div
                    key={data.citizenid}
                    style={{
                      display: 'flex',
                      marginTop: index !== 0 && 10,
                      borderTop: index !== 0 && '1px solid grey',
                      paddingTop: 10,
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/civilian/' + data.citizenid)}
                  >
                    <UserProfile
                      name={data.full_name}
                      citizenid={data.citizenid}
                      biometricsMatch={data.biometricsMatch}
                    />
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
      {
        size.width <= 1122 && (
          <div
            style={{
              width: '80%',
              borderBottom: '1px solid #D6E9FF',
              margin: '0 auto',
              height: 15,
            }}
          />
        )
      }
      <div
        style={{
          width: size.width <= 1122 ? '100%' : '35%',
          marginTop: size.width <= 1122 ? 15 : undefined,
        }}
      >
        <div
          style={{
            width: '80%',
            margin: size.width <= 1122 ? '0 auto' : undefined,
            backgroundColor: '#ffffff',
            padding: '25px 25px',
            borderRadius: 3,
          }}
        >
          <h3>Openstaande Arrestatiebevelen</h3>
          <span>Geen arrestatiebevelen gevonden</span>
          <div>
            {
              [].map((data, index) => (
                <div
                  key={index}
                  style={{
                    marginTop: 10,
                  }}
                >
                  <SearchWarrantTag
                    text="Arrestatiebevel Thomas Muur"
                  />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Civilians;
