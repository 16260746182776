import * as React from 'react';

const LicensePlate = ({ children }) => (
  <div
    style={{
      display: 'flex',
      width: 115,
    }}
  >
    <div
      style={{
        width: '15%',
        backgroundColor: '#1A53BC',
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
      }}
    />
    <div
      style={{
        backgroundColor: '#ecc42e',
        color: '#000000',
        padding: '5px 10px',
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        width: '85%',
      }}
    >
      {children}
    </div>
  </div>
);

export default LicensePlate;
