import * as React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";

import ChevronDown from "../assets/icons/ChevronDown";

const Header = () => {
  const location = useLocation();

  const user = useSelector(state => state.user);

  return (
    <div>
      <div
        style={{
          width: '100vw',
          height: 40,
          backgroundColor: '#F8F8FF',
          display: 'flex',
        }}
      >
        <div
          style={{
            width: 1280,
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >

          {
            user.logged_in && (
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                }}
              >
                {user.username}

                <div
                  style={{
                    marginLeft: 5,
                  }}
                >
                  <ChevronDown
                    width={20}
                    height={20}
                    color="#333333"
                  />
                </div>
              </div>
            )
          }

        </div>
      </div>
      <div
        style={{
          height: 70,
          width: '100vw',
          backgroundColor: '#FFFFFF',
        }}
      >
        <div
          style={{
            maxWidth: 1280,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <h1>Politie</h1>
        </div>
      </div>
      <div
        style={{
          height: 60,
          width: '100vw',
          backgroundColor: '#004682',
        }}
      >
        <div
          style={{
            maxWidth: 1280,
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {
            user.logged_in && (
              <ul
                style={{
                  listStyleType: 'none',
                  display: 'flex',
                  alignItems: 'flex-start',
                  color: '#ffffff',
                  padding: 0,
                  margin: 0,
                }}
              >
                <li
                  style={{
                    borderBottom: location.pathname === '/' ? '3px solid #ffffff' : 'none',
                    height: 60,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Link
                    to="/"
                    style={{
                      color: '#ffffff',
                      textDecoration: 'none',
                    }}
                  >
                    Burgers
                  </Link>
                </li>
                <li
                  style={{
                    borderBottom: location.pathname === '/vehicles' ? '3px solid #ffffff' : 'none',
                    marginLeft: 25,
                    height: 60,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Link
                    to="/vehicles"
                    style={{
                      color: '#ffffff',
                      textDecoration: 'none',
                    }}
                  >
                    Voertuigen
                  </Link>
                </li>
                <li
                  style={{
                    marginLeft: 25,
                    height: 60,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#cccccc',
                    fontWeight: 300,
                  }}
                >
                  Rapporten
                </li>
                <li
                  style={{
                    marginLeft: 25,
                    height: 60,
                    display: 'flex',
                    alignItems: 'center',
                    color: '#cccccc',
                    fontWeight: 300,
                  }}
                >
                  Arrestatiebevelen
                </li>
              </ul>
            )
          }
        </div>
      </div>
    </div>
  )
};

export default Header;
