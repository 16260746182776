import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import {Provider} from 'react-redux';
import axios from 'axios';

import store from './redux/store';

import './index.css';

import Civilians from './pages/Civilians';
import Vehicles from './pages/Vehicles';
import SingleCivilian from "./pages/SingleCivilian";
import Login from "./pages/Login";
import Layout from "./pages/Layout";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const router = createBrowserRouter([
  {
    element: <Layout/>,
    errorElement: <div>Oops</div>,
    children: [
      {
        path: '/',
        element: <Civilians/>,
      },
      {
        path: '/login',
        element: <Login/>,
      },
      {
        path: '/civilian/:id',
        element: <SingleCivilian/>,
      },
      {
        path: '/vehicles',
        element: <Vehicles/>
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router}/>
    </Provider>
  // </React.StrictMode>
);
