import * as React from 'react';
import UserProfile from "../components/UserProfile";
import useDimensions from "../services/useDimensions";
import {useParams} from "react-router-dom";
import axios from "axios";
import Loader from "../components/Loader";
import LicensePlate from "../components/LicensePlate";
import Input from "../components/Input";
import SquareButton from "../components/SquareButton";
import Modal from "../components/Modal";
import CriminalRecordItem from "../components/CriminalRecordItem";

const SingleCivilian = () => {
  const size = useDimensions();
  const params = useParams();

  const [data, setData] = React.useState({});
  const [bills, setBills] = React.useState(null);
  const [billsLoading, setBillsLoading] = React.useState(false);

  const [fingerprint, setFingerprint] = React.useState('');
  const [fingerprintLoading, setFingerprintLoading] = React.useState(false);

  const [dna, setDna] = React.useState('');
  const [dnaLoading, setDnaLoading] = React.useState(false);

  const [recordOpen, setRecordOpen] = React.useState(false);
  const [criminalRecord, setCriminalRecord] = React.useState(null);

  React.useEffect(() => {
    axios.get('/api/meos/players/' + params.id)
      .then(({data}) => {
        setData(data);
      })
      .catch(() => alert('Something went wrong.'));
  }, [params]);

  const loadBills = () => {
    if (billsLoading) return;

    setBillsLoading(true);
    axios.get('/api/meos/players/' + params.id + '/bills')
      .then(({data}) => setBills(data))
      .catch(() => alert('Something went wrong.'))
      .finally(() => setBillsLoading(false));
  };

  const saveFingerprint = () => {
    if (fingerprintLoading) return;

    setFingerprintLoading(true);
    axios.post('/api/meos/players/' + params.id + '/fingerprint', {
      value: fingerprint,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(() => {
        setData(prevState => ({
          ...prevState,
          biometrics: [
            ...prevState.biometrics,
            {
              type: 'fingerprint',
              value: fingerprint,
            }
          ]
        }));
      })
      .catch(() => alert('Something went wrong.'))
      .finally(() => setFingerprintLoading(false));
  };

  const saveDna = () => {
    if (dnaLoading) return;

    setDnaLoading(true);
    axios.post('/api/meos/players/' + params.id + '/dna', {
      value: dna,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(() => {
        setData(prevState => ({
          ...prevState,
          biometrics: [
            ...prevState.biometrics,
            {
              type: 'dna',
              value: dna,
            }
          ]
        }));
      })
      .catch(() => alert('Something went wrong.'))
      .finally(() => setDnaLoading(false));
  };

  const openCriminalRecord = () => {
    setRecordOpen(true);

    if (criminalRecord) return;

    axios.get('/api/meos/players/' + params.id + '/criminal-records')
      .then(({ data}) => setCriminalRecord(data))
      .catch((err) => {
        alert('Er ging iets mis.');
        console.error(err);
      })
  };

  const existingFingerprint = data.biometrics?.find(i => i.type === 'fingerprint');
  const existingDna = data.biometrics?.find(i => i.type === 'dna');

  return (
    <div
      style={{
        paddingTop: 35,
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {
        !data.citizenid
        && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100vh',
            }}
          >
            <Loader/>
          </div>
        )
      }
      {
        !!data.citizenid && (
          <>
            <div
              style={{
                width: size.width <= 1122 ? '100%' : '65%',
              }}
            >
              <div
                style={{
                  width: '80%',
                  backgroundColor: '#ffffff',
                  margin: '0 auto',
                  padding: '25px 25px',
                  borderRadius: 3,
                }}
              >
                <UserProfile
                  name={data.full_name}
                  citizenid={data.citizenid}
                  extraInfo={[
                    ['Geboortedatum', data.birth_date],
                    ['Totaal boetebedrag', `€ ${data.bill_summary.total} (${data.bill_summary.count} boetes)`]
                  ]}
                />
              </div>

              {
                data.bill_summary.total > 0 && (
                  <>
                    <div
                      style={{
                        width: '80%',
                        borderBottom: '1px solid #D6E9FF',
                        margin: '0 auto',
                        height: 15,
                      }}
                    />

                    {!bills && (
                      <div
                        style={{
                          marginTop: 10,
                          width: '80%',
                          backgroundColor: '#ffffff',
                          margin: '15px auto 0',
                          padding: '25px 25px',
                          borderRadius: 3,
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                        onClick={loadBills}
                      >
                        <span>Toon boetes</span>
                        {
                          billsLoading && (
                            <Loader
                              size={20}
                            />
                          )
                        }
                      </div>
                    )}
                    {bills && (
                      <div
                        style={{
                          marginTop: 10,
                          width: '80%',
                          backgroundColor: '#ffffff',
                          margin: '15px auto 0',
                          padding: '25px 25px',
                          borderRadius: 3,
                        }}
                        onClick={loadBills}
                      >
                        <h3>Boetes</h3>

                        {
                          bills.map((data, index) => {
                            const limitPayDate = Date.parse(data.limit_pay_date);
                            const fineOverdue = Date.now() > limitPayDate;

                            return (
                              <div
                                key={data.id}
                                style={{
                                  display: 'flex',
                                  marginTop: index !== 0 && 10,
                                  borderTop: index !== 0 && '1px solid grey',
                                  paddingTop: 10,
                                  justifyContent: 'space-between',
                                }}
                              >
                                <div>
                                  <span>Reden: {data.item}</span>
                                  <br/>
                                  <span>Bedrag: € {data.invoice_value}</span>
                                  <br/>
                                  <span>Uitgeschreven op: {data.sent_date}</span>
                                  <br/>
                                  <span>
                                    Betalen voor:{' '}
                                    <span
                                      style={{
                                        color: fineOverdue ? '#ff0000' : 'inherit'
                                      }}
                                    >
                                      {data.limit_pay_date}
                                      {
                                        fineOverdue && ' (Te laat)'
                                      }
                                    </span>
                                  </span>
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    )}
                  </>
                )
              }

              <div
                style={{
                  marginTop: 10,
                  width: '80%',
                  backgroundColor: '#ffffff',
                  margin: '15px auto 0',
                  padding: '25px 25px',
                  borderRadius: 3,
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={openCriminalRecord}
              >
                <span>Open {data.criminal_records_count || ''} strafbladen</span>
              </div>


            </div>
            {
              size.width <= 1122 && (
                <div
                  style={{
                    width: '80%',
                    borderBottom: '1px solid #D6E9FF',
                    margin: '0 auto',
                    height: 15,
                  }}
                />
              )
            }

            <div
              style={{
                width: size.width <= 1122 ? '100%' : '35%',
                marginTop: size.width <= 1122 ? 15 : undefined,
              }}
            >
              <div
                style={{
                  width: '80%',
                  margin: size.width <= 1122 ? '0 auto' : undefined,
                  backgroundColor: '#ffffff',
                  padding: '25px 25px',
                  borderRadius: 3,
                }}
              >
                <h3>Biometrie</h3>

                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <span>Vingerafdruk</span>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        width: '80%',
                        marginRight: 5,
                      }}
                    >
                      <Input
                        placeholder={'LQ305H57lNQ0824'}
                        value={existingFingerprint?.value ?? fingerprint}
                        onChange={e => setFingerprint(e.target.value.toUpperCase())}
                        disabled={existingFingerprint}
                      />
                    </div>
                    {
                      !existingFingerprint && (
                        <SquareButton
                          color="green"
                          onClick={saveFingerprint}
                        >
                          {
                            fingerprintLoading
                              ? <Loader
                                color="#ffffff"
                                size={20}
                              />
                              : '✓'
                          }
                        </SquareButton>
                      )
                    }
                  </div>
                </div>

                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <span>DNA</span>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        width: '80%',
                        marginRight: 5,
                      }}
                    >
                      <Input
                        placeholder={'LQ305H57lNQ0824'}
                        value={existingDna?.value ?? dna}
                        onChange={e => setDna(e.target.value.toUpperCase())}
                        disabled={existingDna}
                      />
                    </div>
                    {
                      !existingDna && (
                        <SquareButton
                          color="green"
                          onClick={saveDna}
                        >
                          {
                            dnaLoading
                              ? <Loader
                                color="#ffffff"
                                size={20}
                              />
                              : '✓'
                          }
                        </SquareButton>
                      )
                    }
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: '80%',
                  borderBottom: '1px solid #D6E9FF',
                  height: 15,
                  margin: size.width <= 1122 && '10px auto 0',
                }}
              />

              <div
                style={{
                  width: '80%',
                  margin: size.width <= 1122 ? '0 auto' : undefined,
                  backgroundColor: '#ffffff',
                  padding: '25px 25px',
                  borderRadius: 3,
                  marginTop: 15,
                }}
              >
                <h3>Voertuigen</h3>
                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  {
                    data.vehicles.map((i, index) => (
                      <div
                        key={i.plate}
                        style={{
                          display: 'flex',
                          marginTop: index !== 0 && 15
                        }}
                      >
                        <span>{i.vehicle}</span>
                        <div
                          style={{
                            marginLeft: 25,
                          }}
                        >
                          <LicensePlate>{i.plate}</LicensePlate>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </>
        )
      }
      <Modal
        open={recordOpen}
        onClose={() => setRecordOpen(false)}
      >
        {
          !criminalRecord
            ? <Loader />
            : (
              <>
                <CriminalRecordItem
                  setCriminalRecord={setCriminalRecord}
                />
                {
                  criminalRecord.map((record) => (
                    <CriminalRecordItem
                      key={record.id}
                      id={record.id}
                      reason={record.reason}
                      fine={record.fine}
                      sentence={record.prison_sentence}
                      canEdit={record.can_edit}
                      setCriminalRecord={setCriminalRecord}
                    />
                  ))
                }
              </>
            )
        }
      </Modal>
    </div>
  );
};

export default SingleCivilian;
