import * as React from 'react';

const UserProfile = (props) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    }}
  >
    <div
      style={{
        display: 'flex',
      }}
    >
      <div
        style={{
          height: 95,
          width: 74,
          backgroundColor: 'grey',
        }}
      />
      <div
        style={{
          marginTop: 10,
          marginLeft: 10,
        }}
      >
        <h4
          style={{
            margin: 0,
            textDecoration: 'bold',
          }}
        >
          {props.name}
        </h4>
        <span>{props.citizenid}</span>
        {
          props.extraInfo?.map(([key, value]) => (
            <React.Fragment key={key}>
              <br/>
              <span>{key}: {value}</span>
            </React.Fragment>
          ))
        }
      </div>
    </div>
    {
      props.biometricsMatch && (
        <div>
          <span
            style={{
              color: '#B33A3A',
              fontWeight: 600,
            }}
          >
            {props.biometricsMatch.toUpperCase()} MATCH
          </span>
        </div>
      )
    }
  </div>
);

export default UserProfile;
