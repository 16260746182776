import * as React from 'react';
import useDimensions from "../services/useDimensions";

const Modal = (props) => {
  const size = useDimensions();

  React.useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [props.open]);

  const keyListener = (data) => {
    if (data.code === 'Escape') {
      if (props.onClose) props.onClose();
    }
  };

  React.useEffect(() => {
    if (props.open) {
      window.addEventListener('keyup', keyListener);
    }

    return () => {
      if (props.open) {
        window.removeEventListener('keyup', keyListener);
      }
    }
  }, [props.open]);

  if (!props.open) return null;

  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, .5)',
        top: window.scrollY,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          color: '#ffffff',
          fontSize: 50,
          cursor: 'pointer',
        }}
        onClick={props.onClose}
      >
        X
      </span>
      <div
        style={{
          width: size.width <= 1122 ? '90%' : '50%',
          minHeight: 200,
          backgroundColor: '#ffffff',
          margin: '0 auto',
          borderRadius: 8,
          boxShadow: '3px 3px 8px 0px rgba(0,0,0,0.25)',
          maxHeight: '90%',
          overflow: 'auto',
        }}
      >
        {props.children}
      </div>
    </div>
  );

};

export default Modal;
